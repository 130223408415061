import logo from './logo.svg';
import './App.css';

import Chart from "react-apexcharts";
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faMoon, faSun, faBalanceScaleLeft, faEye, faBolt, faFire } from '@fortawesome/free-solid-svg-icons'
import Slider from '@mui/material/Slider';

import React, { Component } from 'react';

if (!window.localStorage.getItem("tuskiKey")) {
  alert("u wot m8?")
  let s = window.prompt("gime gime")
  console.log(s)
  window.localStorage.setItem("tuskiKey", s)
  throw console.error("KEKLEHOMA");

}
if (window.location.hash == "#newtoken") {

  window.localStorage.removeItem("tuskiKey");
  alert("u wot m8?")
  let s = window.prompt("gime gime")
  console.log(s)
  window.localStorage.setItem("tuskiKey", s)
}
let auth = window.localStorage.getItem("tuskiKey") // for all requests
let headers = {
  'Content-Type': 'application/json',
  'Authorization': "Bearer " + auth
}
class App extends Component {
  constructor() {
    super();
    this.toggleView = this.toggleView.bind(this)
    this.apiDecisions = this.apiDecisions.bind(this)
    this.getTrades = this.getTrades.bind(this)
    this.toggleGraph = this.toggleGraph.bind(this)
    this.changeSafePerc = this.changeSafePerc.bind(this)
    this.buttonDecision = this.buttonDecision.bind(this)
    this.toggleHistory = this.toggleHistory.bind(this)
    this.getMetricHistory = this.getMetricHistory.bind(this)
    this.inactivityTime = this.inactivityTime.bind(this)
    this.endpoint = ""

    let appName = window.location.hostname
    if (appName.includes(".")) {
      appName = appName.split(".")[0]
    }
    if (appName == "hotei" || appName == "raijin") {
      document.querySelector("#candlesticks").className = "show"

    }
    //  this.endpoint = "http://localhost:9997"
    //this.endpoint = "http://raijin.ebisu.vip"
    //   this.endpoint = "http://amaterasu.ebisu.vip"
    this.getMidnightStats();
    this.getAllStats();
    this.getMetricHistory();
    this.getPNLHistory();
    setTimeout(() => {
      this.getFTXBTCPrice(appName)

    }, 1000)

    this.inactivityTime()

    setInterval(() => {

      this.getFTXBTCPrice(appName)
    }, 60000 * 5)
  }
  inactivityTime() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    async function refresher() {
      this.getMidnightStats();
      this.getAllStats();
      this.getMetricHistory();
      this.getPNLHistory();
      //location.href = 'logout.html'
    }
    refresher = refresher.bind(this)
    resetTimer = resetTimer.bind(this)
    function resetTimer() {
      clearTimeout(time);

      time = setTimeout(refresher, 10000)
      // 1000 milliseconds = 1 second
    }
  };
  state = {
    waitingList: [],
    pnlHistory: [],
    pnlPrice: 0,
    collectivePNL: 0,
    safePerc: null,
    collectivePNLPerc: 0,
    imbalances: [],
    direction: "",
    showGraph: false,
    showHistory: false,
    midnightStats: [],
    view: 0,
    historyPage: 0,
    shoppingTable: [{
      coin: "SHIB",
      direction: "LONG",
      multiplier: 140
    }],
    redColors: ["#bb1d5a", "#bb1d5a", "#bb1d5a", "#bb1d5a", "#bb1d5a"],
    greenColors: ["#11dab8", "#11dab8", "#11dab8", "#11dab8", "#11dab8"],
    trades: [{
      coin: "SHIB",
      direction: "LONG",
      multiplier: 140,
      pnl: "$2.50",
      currentPercent: 0.003,
      entry: 1.5,
      amount: "$25",
      enteredAt: new Date().toLocaleTimeString()
    }],

    series: [{
      name: "Desktops",
      data: []
    }],
    options: {

      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }, fillColor: "#FFF",
        animations: {
          enabled: false,

        }
      },

      colors: ["#13dab8", "#FF0000"],


      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        fillColor: "#ededed1c",
        width: 2
      },
      title: {
        text: 'Sentiment history',
        align: 'middle'
      },
      grid: {
        borderColor: "#ededed1c",
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.1
        },
      },
      xaxis: {
        tickPlacement: 'between',
        type: 'datetime',
        labels: {
          formatter: function (val) {
            return new Date(val).toLocaleString()
          }
        }
      },
      yaxis: {
        opposite: true,
        labels: {
          show: true,
          align: 'right',
        }
      }
      // },
      // xaxis: {
      //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      // }
    },

  }
  componentDidMount() {
    // this.getTrades();
    // this.getWaitingList();
    // this.getOrderManagerStats();
    // this.getGeneralStats();
    // this.getImbalances();
    // this.getDailies();
    // this.getHistoricalTrades();

  }
  getTrades() {
    fetch(`http://192.168.0.80:9991/trades`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {

        let trades = [];
        for (const [acc, trade] of Object.entries(d.currentTrades)) {
          trades.push({
            coin: trade.future.split("-")[0],
            direction: trade.side,
            multiplier: 150,
            pnl: trade.realizedPnl.toFixed(2),
            amount: trade.size,
          })

        }

        this.setState({ ...this.state, trades: trades })
      });

  }
  getFTXBTCPrice(appName) {

    let path = `prices/BTC-PERP/300/250`;
    if (appName == "raijin") {
      path = `prices/BTC-MOVE-${('0' + (new Date().getMonth() + 1)).slice(-2)}${new Date().getDate()}/3600/250`
    }
    fetch(`http://tsukiterasu.ebisu.vip/${path}`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {
        console.log("FTX", d)
        // let trades = [];
        // for (const [acc, trade] of Object.entries(d.currentTrades)) {
        //   trades.push({
        //     coin: trade.future.split("-")[0],
        //     direction: trade.side,
        //     multiplier: 150,
        //     pnl: trade.realizedPnl.toFixed(2),
        //     amount: trade.size,
        //   })

        // }
        this.setState({ ...this.state, chart: d })
        this.renderChart(this.state?.chart?.result, this.state.running?.barrier)

        // this.setState({ ...this.state, trades: trades })
      });

  }

  getAllStats() {
    fetch(`${this.endpoint}/stats`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {

        let runningPositions = []
        let collectiveP = 0
        let collectivePNL = 0
        // d = d.reverse()
        // let newArr = d.map(item => {
        //   return item;
        // })
        for (const [key, value] of Object.entries(d.traderStats.runningPositions)) {
          let distanceFromLiq = (value.estimatedLiquidationPrice - value.recentAverageOpenPrice) / value.recentAverageOpenPrice
          let direction = value.cost < 0 ? "short" : "long"
          let distanceFromProfit = (value[direction == "long" ? "entryPrice" : "recentAverageOpenPrice"] - value[direction == "long" ? "recentAverageOpenPrice" : "entryPrice"]) / value.recentAverageOpenPrice
          value.distanceFromLiq = distanceFromLiq * 100
          value.distanceFromProfit = distanceFromProfit * 100
          collectiveP += distanceFromProfit
          collectivePNL += value.recentPnl
          runningPositions.push(value)
        }
        let pnlPrice = 0
        d.history.forEach((his) => {
          pnlPrice += his.recentPnl
        })
        let maxPnlPages = d.history.length / 10
        this.setState({
          ...this.state, runningPositions,
          safePerc: d?.running?.safePerc,
          running: d?.running,
          pnlPrice, collectivePNL: collectivePNL, collectivePNLPerc: collectiveP

        })
        this.renderChart(this.state?.chart?.result, this.state.running?.barrier)
      });

  }
  getPNLHistory() {
    fetch(`${this.endpoint}/earnings`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {

        d.sort(function (a, b) {
          return new Date(a.finishedAt).getTime() - new Date(b.finishedAt).getTime();
        })
        d.reverse()
        console.log(d)
        let pnlPrice = 0
        d.forEach((his) => {
          pnlPrice += his.recentPnl
        })
        this.setState({
          pnlHistory: d,
          pnlPrice
        })
      });

  }
  getMetricHistory() {
    try {


      fetch(`${this.endpoint}/metrics`,
        {
          method: 'GET',
          headers,
          // body: JSON.stringify(pak),
        }
      )
        .then(response => response.json())
        .then(d => {
          let series = [{
            name: "",
            data: []
          }]
          d.forEach((item) => {
            if (item.collectivePerc) series[0].data.push([item.calculatedAt, (parseFloat(item.collectivePerc) * 100).toFixed(0)])
          })
          this.setState({ ...this.state, series: series })
        });
    } catch (e) {

    }
  }
  getGeneralStats() {
    fetch(`${this.endpoint}/stats`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {

        let perc = 0;
        let direction = "short"
        if (d.generalZakuDirection.buys < d.generalZakuDirection.sells) {
          perc = d.generalZakuDirection.sells / d.generalZakuDirection.total
          direction = "short";
        } else {
          perc = d.generalZakuDirection.buys / d.generalZakuDirection.total
          direction = "long";

        }

        this.setState({
          generalZakuDirection: { ...d.generalZakuDirection },
          direction: direction,
          sentimentPerc: ((perc) * 100).toFixed(2)
        })
        this.forceUpdate();
      });

  }
  getMidnightStats() {
    try {


      fetch(`${this.endpoint}/repo`,
        {
          method: 'GET',
          headers,
          // body: JSON.stringify(pak),
        }
      )
        .then(response => response.json())
        .then(d => {


          let arr = []
          let positives = 0
          let negatives = 0
          let collectivePerc = 0;
          for (const coin in d) {
            let ob = d[coin];
            collectivePerc += ob.difference
            if (ob.difference > 0) {
              positives++
            } else {
              negatives++
            }
            arr.push({ coin: coin, ...d[coin] })

          }
          for (const coin of arr) {
            coin.perfOfPie = (coin.difference / collectivePerc) * 100;

          }
          arr.sort((a, b) => (a.perfOfPie > b.perfOfPie) ? 1 : ((b.perfOfPie > a.perfOfPie) ? -1 : 0))
          arr.reverse();
          let pos = ""
          if (positives > negatives) {
            pos = "short"
          } else {
            pos = "long"
          }
          this.setState({
            midnightStats: [...arr],
            negatives,
            positives,
            collectivePerc: collectivePerc,
            direction: pos,
            sentimentPerc: ((Math.abs(positives - negatives) / Math.max(...[positives, negatives])) * 100).toFixed(1)
          });

          this.forceUpdate();
        });
    } catch (e) {

    }
  }



  calculateBadass(startDate) {

    console.log(startDate)
    var past = new Date(startDate).getTime();
    var now = new Date().getTime();
    var diff = ((now - past) / 60000).toFixed(0);
    return diff
  }

  currentPositions() {
    return <div className='table'>


      <table>
        <tr>
          <th></th>
          <th>Coin</th>
          <th>Direction</th>
          <th>Collateral</th>
          <th>%FromLiq</th>
          <th>%FromProfit <b>({(this.state.collectivePNLPerc * 100).toFixed(2)}%)</b></th>
          <th>PNL <b>(${(this.state.collectivePNL * 1).toFixed(2)})</b></th>
        </tr>

        {this.state.runningPositions?.length > 0 && this.state.runningPositions?.map((item, i) => {
          return <tr key={i}>

            <td><img width={16} src={"/coinIcons/color/" + item.future.split("-")[0].toLowerCase() + ".png"}></img></td>
            <td><a target="_blank" href={`https://ftx.com/trade/${item.future}`}>{item.future.split("-")[0]}</a></td>
            <td>{item?.cost < 0 ? "short" : "long"}</td>
            <td>${item.collateralUsed.toFixed(0)}</td>
            <td>{item.distanceFromLiq.toFixed(1)}%</td>
            <td>{item.distanceFromProfit.toFixed(4)}%</td>
            <td className={item.recentPnl?.toFixed(5).includes("-") ? "red" : "green"}>{item.recentPnl.toFixed(3)}</td>

          </tr>
        })}
      </table>
    </div>
  }
  tradesTable() {
    return <div className='table'>


      <table>
        <tr>


          <th></th>
          <th>Coin</th>
          <th>Midnight</th>
          <th>Now</th>
          <th>% Difference</th>
          <th>Ordered</th>

        </tr>

        {this.state.midnightStats.map((item, i) => {
          return <tr key={i}>

            <td><img src={"/coinIcons/color/" + item.coin.toLowerCase() + ".png"}></img></td>
            <td>{item.coin}</td>

            <td>{item.atMidnight}</td>
            <td>{item.currentPrice}</td>
            <td className={item.difference?.toFixed(5).includes("-") ? "red" : "green"}>{(parseFloat(item.difference) * 100).toFixed(2)}%</td>
            <td className={item.setOrders ? "green" : ""}>{!item.setOrders ? "no" : "yes"}</td>

            <div className='bgPerc' style={{ width: `${item.perfOfPie < 100 ? item.perfOfPie : 100}%` }}></div>

          </tr>
        })}
      </table>
    </div >
  }
  historyTable() {
    let sortable = []
    if (this.state.pnlHistory) {

      for (const [coin, waitItem] of Object.entries(this.state.pnlHistory)) {

      }
      let copied = [...this.state.pnlHistory]
      let uniqueDays = {}

      let collateral = 0;
      let profit = 0;
      let since = this.state.pnlHistory.reverse()[0].finishedAt
      let lastAccountValue = 0
      for (const [key, value] of Object.entries(this.state.pnlHistory)) {
        if (collateral < value.collateralUsed) {
          collateral = value.collateralUsed;
        }

        if (profit < value.recentPnl) {
          profit = value.recentPnl;
        }
        if (value.totalAccountValue) {
          lastAccountValue = value.totalAccountValue;
          // this.setState({ accountValue: lastAccountValue })
        }
        uniqueDays[new Date(value.finishedAt).getDate()] = value.finishedAt
      }

      let maxPages = parseFloat((copied.length).toFixed(0))
      copied = copied.slice(this.state.historyPage, this.state.historyPage + 20);

      return <div>

        <h6 className='green'>Since: {since} Traded days: {Object.entries(uniqueDays).length}</h6>

        <h3 className='green'><b className='green'>${lastAccountValue.toFixed(0)}</b> (acc value)</h3>
        <h5 className='green'><b className='green'>${this.state.pnlPrice.toFixed(2)}</b> (30day profit)</h5>
        <h6>Daily avg: <b className='green'>${(this.state.pnlPrice / 30).toFixed(2)}</b> Highest collateral: <b>${collateral.toFixed(0)}</b> Highest Profit: <b>${profit.toFixed(2)}</b></h6>
        <div className='table'>


          <table>
            <tr>

              <th></th>
              <th>Coin</th>
              <th>Collateral</th>
              <th>Profit</th>
              <th>Date</th>



            </tr>

            {copied.map((item, i) => {
              return <tr key={i}>

                <td><img width={16} src={"/coinIcons/color/" + item.future.split("-")[0].toLowerCase() + ".png"}></img></td>
                <td>{item.future}</td>
                <td>{item.collateralUsed}</td>
                <td className='green'>${item.recentPnl.toFixed(2)}</td>
                <td>{item.finishedAt}</td>


              </tr>
            })}
          </table>
        </div>
        <div>
          <button onClick={() => this.setState({ ...this.state, historyPage: this.state.historyPage -= 20 }, () => { this.forceUpdate() })}>{"<"}</button>
          <p style={{ "display": "inline-block" }}> {(this.state.historyPage).toFixed(0)} / {maxPages}</p>
          <button onClick={() => this.setState({ ...this.state, historyPage: this.state.historyPage += 20 })}>{">"}</button>
        </div>
      </div>
    }
  }
  changeHistoryPage() {

  }
  imbalanceTable() {
    return <div className='table'>


      <table>
        <tr>


          <th></th>
          <th>Coin</th>
          <th>Side</th>
          <th>Sigma</th>
          <th>Multiplier</th>
          <th>AddedAt</th>

        </tr>

        {this.state.imbalances.map((item, i) => {
          return <tr key={i}>


            <td><img src={"/coinIcons/white/" + item.coin.toLowerCase() + ".png"}></img></td>
            <td>{item.coin}</td>
            <td>{item.side}</td>
            <td>{item.sigma}</td>
            <td>{item.multiplier}</td>
            <td>{item.added}</td>


          </tr>
        })}
      </table>
    </div>
  }
  pickWidget(direction) {
    return <div onClick={this.toggleGraph} className={direction == "long" ? "meterRed" : "meterGreen"}>

      <ReactSpeedometer

        minValue={-200}
        maxValue={200}
        segments={10}
        ringWidth={10}

        //  segmentColors={this.state[direction == "long" ? "greenColors" : "redColors"]}
        currentValueText="${value}%"
        value={parseFloat((this.state.collectivePerc * 100).toFixed(0))}
      />
    </div>
  }
  toggleView() {
    if (this.state.view == 1) {
      this.setState({ view: 0 })
    } else {

      this.setState({ view: 1 })
    }
  }
  toggleGraph() {

    let show = this.state.showGraph ? false : true;
    this.setState({ ...this.state, showGraph: show })
  }
  toggleHistory() {

    let show = this.state.showHistory ? false : true;
    this.setState({ ...this.state, showHistory: show })
  }
  buttonDecision(type) {


    if (window.confirm(`Are you sure you want to ${type}?`)) {
      console.log(type)
      this.apiDecisions(type)
    }
  }
  apiDecisions(type) {
    fetch(`${this.endpoint}/${type}`,
      {
        method: 'GET',
        headers,
        // body: JSON.stringify(pak),
      }
    )
      .then(response => response.json())
      .then(d => {



      });

  }
  apiPatch(path, method, body) {
    fetch(`${this.endpoint}/${path}`,
      {
        method: method,
        headers,
        body: JSON.stringify(body),
      }
    )

      .then(d => {


        this.getAllStats();
      });

  }
  manualOverideButtons() {
    return <div className='overrideButtons'>
      <div style={{ width: "300px", display: "inline-block" }}>
        {this.state.safePerc && <i>{this.state.safePerc}%</i>}
        {this.state.safePerc &&

          <Slider
            size="small"
            defaultValue={this.state.safePerc}
            onChange={this.changeSafePerc}
            aria-label="Small"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={-1}
            max={this.state.collectivePNLPerc * 100 > 1 ? (parseFloat(this.state.collectivePNLPerc * 100).toFixed(0)) : 10}
          />
        }
        {!this.state.safePerc && <button onClick={() => this.apiPatch(`safetyperc/1`, "POST", {})}>Enable Safety Perc</button>}
      </div>
      <br />
      <button onClick={() => this.buttonDecision("enterlong")} data-type="enterLong"><b className='green'>Enter Long</b></button>

      <button onClick={() => this.buttonDecision("entershort")} data-type="enterShort"><b className='red'>Enter Short</b></button>

      <button onClick={() => this.buttonDecision("closeall")} data-type="cancel">CloseAll</button>

    </div>
  }
  changeSafePerc(e) {

    this.apiPatch(`safetyperc/${e.target.value}`, "POST", {})
  }
  render() {
    let { direction } = this.state
    let appName = window.location.hostname
    if (appName.includes(".")) {
      appName = appName.split(".")[0]
    }
    return (
      <div className="App">
        <div className="ebisuApps">
          <a href="http://tsukiterasu.ebisu.vip">
            <FontAwesomeIcon icon={faSun} /> <b>Tsukiterasu</b>
          </a>

          <a href="http://tsukuyomi.ebisu.vip">
            <FontAwesomeIcon icon={faMoon} /> <b>Tsukuyomi</b>
          </a>
          <a href="http://Amaterasu.ebisu.vip">
            <FontAwesomeIcon icon={faFire} /> <b>Amaterasu</b>
          </a>

          <a href="http://fukurokuju.ebisu.vip">
            <FontAwesomeIcon icon={faBalanceScaleLeft} /><b>Fukurokuju</b>
          </a>

          <a href="http://hotei.ebisu.vip">
            <FontAwesomeIcon icon={faEye} /><b>Hotei</b>
          </a>
          <a href="http://raijin.ebisu.vip">
            <FontAwesomeIcon icon={faBolt} /><b>raijin</b>
          </a>


        </div>
        <header>
          <h1 onClick={this.toggleHistory}>{appName}
            {/* <button onClick={this.toggleView}></button>*/}
          </h1>
        </header>

        {!this.state.showHistory &&


          <div className='middleContent'>
            {/* <h6>General zScore direction</h6> */}

            {appName !== "hotei" && !this.state.showGraph && this.pickWidget(direction)}

            {this.state.showGraph &&
              <Chart options={this.state.options} series={this.state.series} type="line" height={350}
                onClick={this.toggleGraph} />

            }


            {(appName !== "hotei" || appName !== "raijin") &&
              <h6>Fuku: {direction == "long" ? <b className='red'>Short</b> : <b className='green'>Long</b>} (P: {this.state.positives} N: {this.state.negatives}) - {this.state.sentimentPerc}%</h6>

            }

            {appName == "fukurokuju" && this.manualOverideButtons()}

            {this.currentPositions()}
            {this.tradesTable()}
            <br></br>


          </div>
        }

        {this.state.showHistory &&
          <div>

            {this.historyTable()}


          </div>
        }
      </div >
    );
  }

  renderChart(data, barrier = 0) {
    try {


      if (!data || data.length == 0) return
      var dataTable = window.anychart.data.table();
      document.querySelector("#candlesticks").innerHTML = ""
      console.log("BARRIER", barrier)
      let arrData = []
      data.forEach((d) => {

        arrData.push([new Date(d.startTime),
        parseFloat(d.open.toFixed(2)),
        parseFloat(d.high.toFixed(2)),
        parseFloat(d.low.toFixed(2)),
        parseFloat(d.close.toFixed(2)),
        d.liqRegion?.total,
        d.volume * d.close])
      })
      dataTable.addData(arrData);

      // map data
      var mapping = dataTable.mapAs({
        'open': 1, 'high': 2, 'low': 3, 'close': 4, "liq": 5, "volume": 6

      });

      // set the chart type
      var chart = window.anychart.stock(true);


      // set the series
      var series = chart.plot(0).candlestick(mapping);
      series.name("BTC");


      // set the chart title

      //  chart.title("EUR USD Historical Trade Data");
      chart.background().fill("#101010")
      var plot = chart.plot(0);
      // create a plot

      let upper = barrier + (barrier * 0.00951);
      let annotations =
      {
        "annotationsList": [
          {
            "enabled": true,
            "type": "line",
            "allowEdit": false,
            stroke: {
              color: '#bb0561eb', thickness: 2, dash: '5 5', lineCap: 'round'
            },

            "xAnchor": 0,
            "valueAnchor": upper,
            "secondXAnchor": new Date().getTime(),
            "secondValueAnchor": upper,
          },

          {
            "enabled": true,
            "type": "line",
            "allowEdit": false,
            stroke: {
              color: '#2bbd73', thickness: 2, dash: '1 5', lineCap: 'round'
            },

            "xAnchor": 0,
            "valueAnchor": barrier,
            "secondXAnchor": new Date().getTime(),
            "secondValueAnchor": barrier,
          }


        ]
      }

      console.log(annotations)
      plot.annotations().fromJson(annotations);






      chart.scroller().xAxis(true);
      // map "open" values for the scroller
      // openValue = dataTable.mapAs();
      // openValue.addField('value', 2);
      // // create a scroller series with the mapped data
      // chart.scroller().column(openValue);
      chart.crosshair().displayMode("float");

      // modify the color of candlesticks making them black and white
      series.risingStroke("#26a69a");
      series.risingFill("#26a69a");
      series.fallingStroke("#ef5350");
      series.fallingFill("#ef5350");
      //   plot.annotations().startDrawing({ type: "triangle", color: "red" });
      let eventMarkers = []

      var ema20 = plot.ema(mapping, 200).series();

      ema20.stroke('white');
      plot.eventMarkers().tooltip().titleFormat("{%short_desc} ({%symbol})");
      plot.eventMarkers().tooltip().format("Event: {%description}");
      plot.eventMarkers().position("series");
      plot.eventMarkers().fieldName("close");
      // set the container id
      chart.container('candlesticks');
      chart.animation(true);

      var indicator1 = chart.plot(0).priceIndicator();
      indicator1.value('last-visible');
      indicator1.fallingStroke('#EF9A9A', 1, "1 2");
      indicator1.fallingLabel({ background: '#F44336' });
      indicator1.risingStroke('#4CAF50', 1, "1 2");
      indicator1.risingLabel({ background: '#A5D6A7' });

      var tooltip = series.tooltip();
      tooltip.title().text("Content");
      //   tooltip.format("{%ts} is a {%close} month\nLowest temp: {%open}&deg;C vol ${%symbol} and {%value}");
      tooltip.format(function () {
        let liq = this.getData("liq")

        return (
          `Open ${this.open} \n
                        High ${this.high} \n
                        Low ${this.low} \n
                        Close ${this.close} \n
                        `
        );
      });

      var rangeSelector = window.anychart.ui.rangeSelector();

      // get a copy of existing ranges
      var customRanges = rangeSelector.ranges();

      // remove the last element
      customRanges.pop();
      customRanges.pop();
      customRanges.pop();
      customRanges.pop();

      // modify the first element
      customRanges[0].type = "unit";
      customRanges[0].unit = "hours";
      customRanges[0].count = 6;
      customRanges[0].default = true;
      customRanges[0].text = "6H";
      customRanges[1].type = "unit";
      customRanges[1].unit = "hour";
      customRanges[1].count = 12;
      customRanges[1].default = true;
      customRanges[1].text = "12H";
      customRanges[2].type = "unit";
      customRanges[2].unit = "day";
      customRanges[2].count = 1;
      customRanges[2].default = true;
      customRanges[2].text = "1D";


      rangeSelector.ranges(customRanges);
      // draw the chart
      chart.draw();

      rangeSelector.render(chart);
      document.querySelector("#candlesticks > div > div.anychart-range-selector.anychart-range-selector-inside > button:nth-child(2)").click();

    } catch (e) {

    }
  }
}

export default App;
